import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "become-student-page-spanish" */'./spanishComponent')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/become-a-student', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class BecomeStudentPageSpanish extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}