import {
  GET_CAREERS_PAGE_DATA_SUCCESS,
  GET_CAREERS_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  careers: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_CAREERS_PAGE_DATA_SUCCESS:
      newState.careers = action.careers;
      break;
    case GET_CAREERS_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Careers Page' data");
      break;
  }
  return newState;
};
