export const PAGE_DATA_LOAD_SUCCESS = 'global/PAGE_DATA_LOAD_SUCCESS';
export const PAGE_DATA_LOAD_FAILURE = 'global/PAGE_DATA_LOAD_FAILURE';

export const OPEN_NAVIGATION_SECTION = 'global/OPEN_NAVIGATION_SECTION';
export const OPEN_MOBILE_NAVIGATION  = 'global/OPEN_MOBILE_NAVIGATION';
export const CLOSE_MOBILE_NAVIGATION = 'global/CLOSE_MOBILE_NAVIGATION';

export const OPEN_LIGHTBOX           = 'global/OPEN_LIGHTBOX';
export const CLOSE_LIGHTBOX          = 'global/CLOSE_LIGHTBOX';

export const SHIPPING_CHARGE = 13; // 13 dollars
export const SHIPPING_CHARGE_DISPLAY = '$13.00'; // 13 dollars

export const routeNavigationMapping = {
  'become-a-student'          : 'new-to-marwen',
  'parents-and-families'      : 'new-to-marwen',
  'educators-and-organizers'  : 'new-to-marwen',
  'become-a-teacher'          : 'new-to-marwen',
  'latest-news'               : 'students-and-alumni',
  'courses-and-workshops'     : 'students-and-alumni',
  'college-and-career-support': 'students-and-alumni',
  'resources-center'          : 'teaching-artists',
  'artwork'                   : 'galleries',
  'courses-in-action'         : 'galleries',
  'past-events'               : 'galleries',
  'what-we-do'                : 'our-impact',
  'our-facility'              : 'our-impact',
  'our-people'                : 'our-impact',
  'news-and-publications'     : 'our-impact',
  'our-supporters'            : 'our-impact',
  'attend-an-event'           : 'ways-to-support',
  'rent-space'                : 'ways-to-support',
  'shop'                      : 'shop',
  'checkout'                  : 'shop',
  'registration'              : 'registration',
  'donate'                    : 'donate',
};