import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getCareersPageDataSuccessAction,
  getCareersPageDataFailureAction,
} from './actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.careersPageReducer,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "careers-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/careers', {}, data)
      .then(({ data: { pageData, careers }}) => {
        dispatch(getCareersPageDataSuccessAction({ careers }));
        dispatch(pageDataLoadSuccessAction(pageData));
      })
}], mapStateToProps)
export default class CareersPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}