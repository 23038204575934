import React from 'react';
import PropTypes from 'prop-types';
import { generateAssetUrl } from '../../util/asset-urls'

const S3Background = ({ className, children, path, fileName, asset }) => (
<div
  className={`s3-background ${className || ''}`}
  style={{
    backgroundImage : `url('${generateAssetUrl(fileName, path, asset)}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize  : 'cover',
    backgroundPosition: 'center center',
  }}
>
{ children }
</div>
);

S3Background.propTypes = {
  className: PropTypes.string,
  children : PropTypes.object,
  path     : PropTypes.string,
  fileName : PropTypes.string.isRequired,
  asset    : PropTypes.bool,
};

export default S3Background;
