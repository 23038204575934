import {
  GET_CONTACT_PAGE_DATA_SUCCESS,
  GET_CONTACT_PAGE_DATA_FAILURE,
} from './constants';

export const getContactPageDataSuccessAction = data => ({
  type: GET_CONTACT_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getContactPageDataFailureAction() {
  return {
    type: GET_CONTACT_PAGE_DATA_FAILURE,
  }
}
