import {
  GET_COURSES_PAGE_DATA_SUCCESS,
  GET_COURSES_PAGE_DATA_FAILURE,
} from './constants';

export const getCoursesPageDataSuccessAction = data =>({
  type: GET_COURSES_PAGE_DATA_SUCCESS,
  ...data,
})

export  const getCoursesPageDataFailureAction = () => ({
  type: GET_COURSES_PAGE_DATA_FAILURE,
})
