import React from 'react';
import {
  PAGE_DATA_LOAD_SUCCESS,
  PAGE_DATA_LOAD_FAILURE,
  OPEN_NAVIGATION_SECTION,
  OPEN_MOBILE_NAVIGATION,
  CLOSE_MOBILE_NAVIGATION,
  OPEN_LIGHTBOX,
  CLOSE_LIGHTBOX,
  routeNavigationMapping,
} from './global-constants';
import { LOCATION_CHANGE } from 'connected-react-router';

let initialState = {
  pageData             : {},
  currentActiveSection : '',
  currentNavSection    : '',
  mobileNavOpen        : false,
  pagesVisited         : 0,
  lightBoxConfig       : {},
};

// This is so we can load in the site configuration
export const setGlobalInitialState = state => {
  initialState = {
    ...initialState,
    ...state,
  };
}

const scrollToAnchor = anchor => {
  setTimeout(() => {
    const ele = document.querySelector(anchor);
    if(ele) window.scrollTo(0, ele.getBoundingClientRect().top);
  }, 1000);
}

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch(action.type) {
    //load page data
    case PAGE_DATA_LOAD_SUCCESS:
      newState.pageData = action.pageData;
      break;
    case PAGE_DATA_LOAD_FAILURE:
      console.warn('Problem loading page data');
      break;

    // navigation state
    case OPEN_NAVIGATION_SECTION:
      newState.currentNavSection = action.section;
      break;
    case OPEN_MOBILE_NAVIGATION:
      newState.mobileNavOpen = true;
      break;
    case CLOSE_MOBILE_NAVIGATION:
      newState.mobileNavOpen = false;
      newState.currentNavSection = '';
      break;

    // handle page routes
    case LOCATION_CHANGE:
      if(action.payload.location.hash)
        scrollToAnchor(action.payload.location.hash);

      // always scroll top
      window.scrollTo(0, 0);
      let pathname = action.payload.location?.pathname || action.payload.location?.location?.pathname;
      newState.currentActiveSection = routeNavigationMapping[pathname.split('/')[1]] || '';
      newState.pagesVisited++;
      break;
    case OPEN_LIGHTBOX:
      newState.lightBoxConfig = action.lightBoxConfig;
      break;
    case CLOSE_LIGHTBOX:
      newState.lightBoxConfig = {};
      break;
    default: break;
  }
  return newState;
};

export default reducer;

