import {
  GET_RENTAL_SPACE_DATA_SUCCESS,
} from './constants';

const initialState = {
  rentalSpaces: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_RENTAL_SPACE_DATA_SUCCESS:
      newState.rentalSpaces = action.rentalSpaces;
      break;
  }
  return newState;
};
