import {
  GET_ATTEND_EVENT_PAGE_DATA_SUCCESS,
  GET_ATTEND_EVENT_PAGE_DATA_FAILURE,
} from './constants';

export const getAttendEventPageDataSuccessAction = data => ({
  type: GET_ATTEND_EVENT_PAGE_DATA_SUCCESS,
  ...data,
})

export function getAttendEventPageDataFailureAction() {
  return {
    type: GET_ATTEND_EVENT_PAGE_DATA_FAILURE,
  };
}
