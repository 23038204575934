import React from 'react';

import App from './app';
import HomePage                 from '../pages/HomePage';
import BecomeStudentPage        from '../pages/BecomeStudentPage';
import BecomeStudentPageSpanish from '../pages/BecomeStudentPage/spanish';
import ParentsPage              from '../pages/ParentsPage';
import ParentsPageSpanish       from '../pages/ParentsPage/spanish';
import BecomeTeacherPage        from '../pages/BecomeTeacherPage';
import EducatorsPage            from '../pages/EducatorsPage';
import LatestNewsPage           from '../pages/LatestNewsPage';
import CoursesPage              from '../pages/CoursesPage';
import CollegeCenterPage        from '../pages/CollegeCenterPage';
import CollegeCenterPageSpanish from '../pages/CollegeCenterPage/spanish';
import ResourcesPage            from '../pages/ResourcesPage';
import ArtworkPage              from '../pages/ArtworkPage';
import GalleryPage              from '../pages/GalleryPage';
import CoursesInActionPage      from '../pages/CoursesInActionPage';
import PastEventsPage           from '../pages/PastEventsPage';
import WhatWeDoPage             from '../pages/WhatWeDoPage';
import BuildingPage             from '../pages/BuildingPage';
import PeoplePage               from '../pages/PeoplePage';
import NewsPage                 from '../pages/NewsPage';
import DonatePage               from '../pages/DonatePage';
import DonationSuccessPage      from '../pages/DonationSuccessPage';
import AttendEventPage          from '../pages/AttendEventPage';
import SpecialEventPage         from '../pages/SpecialEventPage';
import RentSpacePage            from '../pages/RentSpacePage';
import RentSpaceEnquiryPage     from '../pages/RentSpaceEnquiryPage';
import ShopPage                 from '../pages/ShopPage';
import ShopPageNew              from '../pages/ShopPageNew';
// import CartPage                 from '../pages/CartPage';
// import TransactionCompletePage  from '../pages/TransactionCompletePage';
import PrivacyPolicyPage        from '../pages/PrivacyPolicyPage';
import CareersPage              from '../pages/CareersPage';
import ContactPage              from '../pages/ContactPage';
import ThreadlessPage           from '../pages/ThreadlessPage';
import FaqPage                  from '../pages/FaqPage';
import W9Page                   from '../pages/W9Page';
import NotFoundPage             from '../pages/NotFoundPage';

const routes = [{
  component: App,
  routes: [
    {
      path     : '/',
      exact    : true,
      component: HomePage,
    },
    {
      path     : '/become-a-student/es',
      component: BecomeStudentPageSpanish,
    },
    {
      path     : '/become-a-student',
      component: BecomeStudentPage,
    },
    {
      path     : '/parents-and-families/es',
      component: ParentsPageSpanish,
    },
    {
      path     : '/parents-and-families',
      component: ParentsPage,
    },
    {
      path     : '/educators-and-organizers',
      component: EducatorsPage,
    },
    {
      path     : '/become-a-teacher',
      component: BecomeTeacherPage,
    },
    {
      path     : '/latest-news',
      component: LatestNewsPage,
    },
    {
      path     : '/courses-and-workshops',
      component: CoursesPage,
    },
    {
      path     : '/college-and-career-support/es',
      component: CollegeCenterPageSpanish,
    },
    {
      path     : '/college-and-career-support',
      component: CollegeCenterPage,
    },
    {
      path     : '/resources-center',
      component: ResourcesPage,
    },
    {
      path     : '/artwork/:albumid',
      component: GalleryPage,
    },
    {
      path     : '/artwork',
      component: ArtworkPage,
    },
    {
      path     : '/courses-in-action/:albumid',
      component: GalleryPage,
    },
    {
      path     : '/courses-in-action',
      component: CoursesInActionPage,
    },
    {
      path     : '/past-events/:albumid',
      component: GalleryPage,
    },
    {
      path     : '/past-events',
      component: PastEventsPage,
    },
    {
      path     : '/what-we-do',
      component: WhatWeDoPage,
    },
    {
      path     : '/our-facility',
      component: BuildingPage,
    },
    {
      path     : '/our-people',
      component: PeoplePage,
    },
    {
      path     : '/news-and-publications',
      component: NewsPage,
    },
    {
      path     : '/donate',
      component: DonatePage,
    },
    {
      path     : '/donation-success',
      component: DonationSuccessPage,
    },
    {
      path     : '/attend-an-event',
      component: AttendEventPage,
    },
    {
      path     : '/event/:eventid',
      component: SpecialEventPage,
    },
    {
      path     : '/rent-space/inquiry',
      component: RentSpaceEnquiryPage,
    },
    {
      path     : '/rent-space',
      component: RentSpacePage,
    },
    {
      path     : '/archived-shop-catalog',
      component: ShopPage,
    },
    {
      path     : '/shop',
      component: ShopPageNew,
    },
    // {
    //   path     : '/cart',
    //   component: CartPage,
    // },
    // {
    //   path     : '/transaction-complete',
    //   component: TransactionCompletePage,
    // },
    {
      path     : '/careers',
      component: CareersPage,
    },
    {
      path     : '/contact',
      component: ContactPage,
    },
    {
      path     : '/privacy',
      component: PrivacyPolicyPage,
    },
    {
      path     : '/threadless',
      component: ThreadlessPage,
    },
    {
      path     : '/faqs',
      component: FaqPage,
    },
    {
      path     : '/w9',
      component: W9Page,
    },
    {
      path     : '*',
      component: NotFoundPage,
    },
  ],
}];

export default routes;
