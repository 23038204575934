import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getSpecialEventPageDataSuccessAction,
  getSpecialEventPageDataFailureAction,
} from './actions';
import {
  addToCartAction,
} from '../../website-main/app-actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';
import { isBrowser }  from '../../util/environment-detection';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.specialEventPageReducer,
})

const mapDispatchToProps = dispatch => ({
  addToCart: product => dispatch(addToCartAction(product)),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "special-event-page" */'./component')
)

@asyncConnect([{
  promise: ({ match, helpers, store: { dispatch }, data }) =>
    apiRequest(`page/event${isBrowser ? '/'+location.pathname.split('/')[2] : ''}`, {}, data)
      .then(({ data: { event } }) => dispatch(getSpecialEventPageDataSuccessAction(event)))
}], mapStateToProps, mapDispatchToProps)
export default class SpecialEventPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}