import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getNewsPageDataSuccessAction,
  getNewsPageDataFailureAction,
} from './actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.newsPageReducer,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "news-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
     apiRequest('page/news-and-publications', {}, data)
      .then(({ data: { pageData, pressReleases }}) => {
        dispatch(getNewsPageDataSuccessAction({ pressReleases }));
        dispatch(pageDataLoadSuccessAction(pageData));
      })
}], mapStateToProps)
export default class NewsPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}