import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const BackButton = ({ className, pagesVisited, children }) => {

  const history = useHistory();

  const backDestination = () => {
    const { location: { pathname } } = window;
    (pagesVisited > 1)
      ? history.goBack(-1)
      : history.push(
          pathname.indexOf('/registration') === 0
          ? '/registration'
          : '/'
        )
  }

  return (
  <div
    onClick={backDestination}
    className={`back-button ${className || ''}`}
  >
    { children }
  </div>
  );
}


BackButton.propTypes = {
  className   : PropTypes.string,
  pagesVisited: PropTypes.number.isRequired,
  children    : PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  pagesVisited : state.globalReducer.pagesVisited,
})

export default connect(mapStateToProps)(BackButton);
