import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton';
import DeferredBackgroundImage from '../../blocks/DeferredBackgroundImage';
import RoundedCorner from '../../svg/RoundedCorner';

const Hero = ({
  titleText,
  eyebrowText,
  buttonText,
  buttonTo,
  buttonHref,
  buttonOnClick,
  buttonTarget,
  lightBackground,
  subText,
  backgroundImage,
  backgroundImageMd,
  backgroundImageSm,
  className,
  titleClassName,
  eyebrowClassName,
  subTextClassName,
  wrapperClassName,
  angleCornerColor,
  imageIsNotAsset,
}) => (
<div className={`hero mih300 pt72 pt48@md posr oh ${ className || '' }`}>
  {backgroundImage &&
    <DeferredBackgroundImage
      className="w100% h100% t0 posa"
      src={backgroundImage}
      srcMd={backgroundImageMd}
      srcSm={backgroundImageSm}
      autoLoad={true}
      imageIsAsset={!imageIsNotAsset}
    />
  }
  <div className="grid-container posr">
    <div className="hero__wrapper">
      {eyebrowText && titleText &&
      <div>
        <h1 className={`hero__eyebrow mb32 lts5 fz16 fw700 ttu tac ${eyebrowClassName || ''}`}>
          {eyebrowText}
        </h1>
        <div className={`hero__title fw300 ${lightBackground ? 'txsh0-2-10-white' : 'txsh0-2-10-black'} fz48 lh1.2 fz32@md tac ${titleClassName || ''}`}>
          {titleText}
        </div>
      </div>
      }
      {!eyebrowText && titleText &&
      <div>
        <div className={`hero__eyebrow mb32 lts5 fz16 fw700 ttu tac ${eyebrowClassName || ''}`}>
          &nbsp;
        </div>
        <h1 className={`hero__title fw300 ${lightBackground ? 'txsh0-2-10-white' : 'txsh0-2-10-black'} fz48 lh1.2 fz32@md tac ${titleClassName || ''}`}>
          {titleText}
        </h1>
      </div>
      }
      {eyebrowText && !titleText &&
      <div>
        <div className={`hero__eyebrow mb32 lts5 fz16 fw700 ttu tac ${eyebrowClassName || ''}`}>
          { eyebrowText }
        </div>
      </div>
      }
      {subText &&
      <div className={`hero__sub-text ${subTextClassName || ''}`}>
        { subText }
      </div>
      }
      {buttonText && (buttonTo || buttonOnClick || buttonHref) &&
      <div className="tac pt32">
        <PrimaryButton
          text={buttonText}
          to={buttonTo}
          href={buttonHref}
          onClick={buttonOnClick}
          target={buttonTarget}
          className="dib"
        />
      </div>
      }
    </div>
  </div>
  <RoundedCorner className={`posa b0 r0 h160 w160 f-${angleCornerColor || 'purple'}`} />
</div>
);

Hero.propTypes = {
  className        : PropTypes.string,
  titleText        : PropTypes.string,
  eyebrowText      : PropTypes.string,
  buttonText       : PropTypes.string,
  buttonTo         : PropTypes.string,
  buttonHref       : PropTypes.string,
  buttonOnClick    : PropTypes.func,
  buttonTarget     : PropTypes.string,
  lightBackground  : PropTypes.bool,
  subText          : PropTypes.string,
  backgroundImage  : PropTypes.string,
  backgroundImageSm: PropTypes.string,
  titleClassName   : PropTypes.string,
  eyebrowClassName : PropTypes.string,
  subTextClassName : PropTypes.string,
  wrapperClassName : PropTypes.string,
  marginBottom     : PropTypes.string,
  angleCornerColor : PropTypes.string,
};

export default Hero;
