import {
  GET_SPECIAL_EVENT_PAGE_DATA_SUCCESS,
  GET_SPECIAL_EVENT_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  event: {},
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_SPECIAL_EVENT_PAGE_DATA_SUCCESS:
      newState.event = action.event;
      break;
    case GET_SPECIAL_EVENT_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Special Event Page' data");
      break;
  }
  return newState;
};
