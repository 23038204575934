// breakpoint variables also defined _variables.scss
export const bp = {
  lgx: 1500,
  lg : 1080,
  md : 820,
  sm : 650,
  smx: 500,
};

// color variables also defined _variables.scss
export const colors = {
  black     : '#070b08',
  darkGray  : '#4b4b4b',
  gray      : '#7e8081',
  shadow    : '#bfbfbf',
  alabaster : '#f8f8f8',
  offwhite  : '#fffbf7',
  darkBlue  : '#23b3ef',
  midBlue   : '#8bd2db',
  paleBlue  : '#f5feff',
  darkPurple: '#36153b',
  purple    : '#6267ad',
  midPurple : '#694a6e',
  palePurple: '#c2c3db',
  neonYellow: '#d6d15d',
  paleYellow: '#f1e8b8',
  brass     : '#c57b57',
  almond    : '#f1dac4',
  linen     : '#faefe1',
  maroon    : '#6c062c',
  brightRed : '#eb3b43',
  '#070b08' : 'black',
  '#4b4b4b' : 'darkGray',
  '#7e8081' : 'gray',
  '#bfbfbf' : 'shadow',
  '#f8f8f8' : 'alabaster',
  '#fffbf7' : 'offwhite',
  '#23b3ef' : 'dark-blue',
  '#8bd2db' : 'mid-blue',
  '#f5feff' : 'pale-blue',
  '#36153b' : 'dark-purple',
  '#6267ad' : 'purple',
  '#694a6e' : 'mid-purple',
  '#c2c3db' : 'pale-purple',
  '#d6d15d' : 'neon-yellow',
  '#f1e8b8' : 'pale-yellow',
  '#c57b57' : 'brass',
  '#f1dac4' : 'almond',
  '#faefe1' : 'linen',
  '#6c062c' : 'maroon',
  '#eb3b43' : 'bright-red',
};

export const mainRootPathnames = [
  '/',
  '/become-a-student/es',
  '/become-a-student',
  '/parents-and-families/es',
  '/parents-and-families',
  '/educators-and-organizers',
  '/become-a-teacher',
  '/latest-news',
  '/courses-and-workshops',
  '/college-and-career-support/es',
  '/college-and-career-support',
  '/resources-center',
  '/artwork/:albumid',
  '/artwork',
  '/courses-in-action/:albumid',
  '/courses-in-action',
  '/past-events/:albumid',
  '/past-events',
  '/what-we-do',
  '/our-facility',
  '/our-people',
  '/news-and-publications',
  '/donate',
  '/donation-success',
  '/attend-an-event',
  '/event/:eventid',
  '/rent-space',
  '/shop',
  '/cart',
  '/transaction-complete',
  '/careers',
  '/faqs',
  '/contact',
  '/privacy',
];

export const registrationRootPathnames = [
  '/registration',
];

