import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getGalleryPageDataSuccessAction,
  getGalleryPageDataFailureAction,
} from './actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.galleryPageReducer,
})

const mapDispatchToProps = dispatch => ({
  openLightBox  : config => dispatch(openLightboxAction(config)),
  closeLightBox : () => dispatch(closeLightboxAction()),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "gallery-page" */'./component')
)

@asyncConnect([{
  promise: ({ match, helpers, store: { dispatch }, data }) =>
    apiRequest(`page/artwork/${match.params.albumid}`, {}, data)
      .then(({ data: { album }}) => {
        dispatch(getGalleryPageDataSuccessAction(album));
      })
}], mapStateToProps, mapDispatchToProps)
export default class GalleryPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}