import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => (
<svg
  viewBox="0 0 533 102"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`logo ${className || ''}`}
>
  <path d="M184.449,97.252 C178.687,99.531 171.566,101.611 164.449,101.611 C154.277,101.611 152.242,94.361 151.058,87.523 C147.663,92.084 140.378,101.611 127.831,101.611 C116.131,101.611 105.62,91.461 105.62,77.992 C105.62,48.992 140.362,44.418 150.87,43.803 L150.886,36.149 C150.886,24.956 150.886,9.215 139.186,9.215 C122.401,9.215 133.932,34.074 118.499,34.074 C113.757,34.074 108.839,30.758 108.839,23.506 C108.839,8.586 125.96,2.168 139.866,2.168 C161.225,2.168 168.007,12.531 168.007,36.148 L168.007,77.375 C168.12,78.283 166.374,97.275 177.558,94.699 C179.940005,94.2243935 182.246223,93.4273942 184.413,92.33 L184.449,97.252 Z M150.867,50.262 C144.426,50.469 123.621,55.547 123.621,75.022 C123.621,82.322 127.047,89.805 134.777,89.805 C144.441,89.805 150.882,81.305 150.882,66.188 L150.867,50.262 Z"></path>
  <path d="M191.008,95.172 C202.258,95.277 206.188,93.219 206.18,77.027 L206.18,28.34 C206.18,13.024 200.028,11.455 190.934,11.85 L190.907,7.612 L223.3,2.24 L223.3,26.267 L223.64,26.267 C226.523,16.945 232.796,2.24 246.695,2.24 C251.523,2.24 255.847,6.174 255.847,13.011 C255.847,20.257 251.617,24.611 246.695,24.611 C240.595,24.611 241.777,19.845 236.187,19.845 C230.929,19.845 223.3,32.486 223.3,53.82 L223.3,77.027 C223.3,92.827 226.534,95.179 238.468,95.179 L238.484,99.609 L191,99.609 L191.008,95.172 Z"></path>
  <path d="M427.574,96.412 C420.247696,99.8559215 412.268743,101.69074 404.174,101.793 C378.069,101.793 362.643,83.148 362.643,48.762 C362.643,17.477 375.358,2.352 398.073,2.352 C424.686,2.352 429.1,27.805 429.1,45.211 L381.12,45.211 C381.12,66.969 387.901,92.475 411.975,92.475 C417.3671,92.4316353 422.687021,91.230902 427.575,88.954 L427.575,96.413 L427.574,96.412 Z M411.974,36.719 C411.974,23.662 410.841,9.399 397.392,9.399 C382.833,9.399 381.692,28.606 381.119,36.719 L411.974,36.719 Z"></path>
  <path d="M436.836,95.168 C447.191,95.168 452.016,94.445 452.016,77.148 L452.016,28.455 C452.016,12.818 444.973,11.424 436.852,11.773 L436.752,7.787 L469.139,2.352 L469.139,26.8 L469.479,26.8 C473.379,18.306 481.179,2.353 497.279,2.353 C514.572,2.353 517.795,14.163 517.795,29.91 L517.795,77.15 C517.795,94.806 522.877,95.17 532.979,95.17 L532.979,99.639 L487.957,99.639 L487.957,95.17 C497.793,95.17 500.672,91.442 500.672,77.15 L500.672,46.69 C500.672,21.618 498.129,14.99 489.824,14.99 C479.992,14.99 469.137,36.535 469.137,49.379 L469.137,77.146 C469.137,91.437 472.02,95.166 481.856,95.166 L481.856,99.635 L436.836,99.635 L436.836,95.166 L436.836,95.168 Z"></path>
  <polygon points="88.664 0.893 47.652 44.318 47.652 44.334 6.648 0.908 0.113 99.617 16.133 99.647 20.09 37.25 47.652 65.891 47.652 65.875 75.215 37.231 79.176 99.631 95.195 99.602"></polygon>
  <polygon points="355.078 2.881 339.129 2.859 334.781 64.992 309.559 36.617 309.559 36.594 309.551 36.606 309.547 36.606 309.547 36.606 284.332 64.981 279.984 2.85 264.027 2.867 270.297 101.578 309.551 58.164 348.812 101.586"></polygon>
</svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
