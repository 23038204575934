import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  openNavSectionAction,
  openMobileNavAction,
  closeMobileNavAction,
} from '../../global-actions';

import Navigation from './Navigation';

import Logo from '../../svg/Logo';

const mapStateToProps = state => ({
  currentActiveSection : state.globalReducer.currentActiveSection,
  currentNavSection    : state.globalReducer.currentNavSection,
  mobileNavOpen        : state.globalReducer.mobileNavOpen,
})

const mapDispatchToProps = dispatch => ({
  openNavSection : section => dispatch(openNavSectionAction(section)),
  openMobileNav  : () => dispatch(openMobileNavAction()),
  closeMobileNav : () => dispatch(closeMobileNavAction()),
})

@connect(mapStateToProps, mapDispatchToProps)
export default class Header extends React.Component {

  static propTypes = {
    className            : PropTypes.string,
    currentActiveSection : PropTypes.string.isRequired,
    currentNavSection    : PropTypes.string.isRequired,
    mobileNavOpen        : PropTypes.bool.isRequired,
    openNavSection       : PropTypes.func.isRequired,
    openMobileNav        : PropTypes.func.isRequired,
    closeMobileNav       : PropTypes.func.isRequired,
  }

  render() {
    const { className, currentActiveSection, currentNavSection, mobileNavOpen } = this.props;

    return (
    <header className={`header posf w100% z3 bgc-pale-blue ${className || ''} ${currentNavSection || mobileNavOpen ? 'z3' : ''}`}>
      <div className="header__container grid-container--wide pl10 pr10 h100% posr">
        <Link
          to={'/'}
          onClick={this.closeMobileNav}
          className="posa w110 h100% z1"
        >
          <Logo className="posa center-vert f-purple" />
        </Link>
      </div>
      <Navigation
        currentActiveSection={currentActiveSection}
        currentNavSection={currentNavSection}
        mobileNavOpen={mobileNavOpen}
        openNavSection={this.props.openNavSection}
        openMobileNav={this.props.openMobileNav}
        closeMobileNav={this.props.closeMobileNav}
      />
    </header>
    );
  }
}
