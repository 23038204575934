import {
  GET_PEOPLE_PAGE_DATA_SUCCESS,
  GET_PEOPLE_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  people           : [],
  peopleCollections: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_PEOPLE_PAGE_DATA_SUCCESS:
      newState.people = action.people;
      newState.peopleCollections = action.peopleCollections;
      break;
    case GET_PEOPLE_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'People' data");
      break;
  }
  return newState;
};
