import {
  GET_GALLERY_PAGE_DATA_SUCCESS,
  GET_GALLERY_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  album: {},
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_GALLERY_PAGE_DATA_SUCCESS:
      newState.album = action.album;
      break;
    case GET_GALLERY_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Gallery Page' data");
      break;
  }
  return newState;
};
