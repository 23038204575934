import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Hero from '../../components/Hero';

const PrivacyPolicyPage = () => (
<section className="privacy-policy-page pb72">
  <Helmet
    title="Privacy Policy | Marwen"
  />
  <Hero
    className="bgc-black"
    eyebrowText="Privacy Policy"
    eyebrowClassName="c-white"
    titleText="Marwen"
    titleClassName="c-white"
    backgroundImage="/images/PrivacyPolicyPage/hero-lg.jpg"
    backgroundImageSm="/images/PrivacyPolicyPage/hero-sm.jpg"
  />
  <div className="grid-container pt72">
    <p><strong>Terms and Conditions</strong> <br /><br />
    The Marwen Web site (“Site”) is provided to you under these terms and conditions (“Terms and Conditions”). Marwen may at any time revise these Terms and Conditions by updating this posting. By accessing, browsing or using the Site, you accept and agree to, without limitation or qualification, these Terms and Conditions (and any amendments). You are encouraged to visit this page from time to time to review the current Terms and Conditions.</p>

    <hr className="m32-0" />

    <p><strong>Link-Exchange Policy</strong> <br /><br />
    Marwen is pleased to have you link from your Web site to the Marwen Web site, located at&nbsp;www.marwen.org, provided you follow the guidelines listed below. <br /><br />
    You should identify Marwen in one of the following ways: <br /><br />
    by name: Marwen <br />
    by URL:&nbsp;www.marwen.org <br /><br />
    You may not frame or transport viewers to any other location outside Marwen’s Web site using Marwen’s name, or any variation thereof, without the prior written permission of Marwen. <br /><br />
    Linking to the Marwen Web site does not grant you any ownership rights or interest in or to Marwen name or any variation thereof. <br /><br />
    You acknowledge that you have read and understood the rest of Marwen’s standard Terms and Conditions. You accept the terms and conditions set forth and agree to abide by you, including, but not limited to, obtaining, at your sole expense and based on your sole legal judgment, any copyright or other intellectual property rights from third parties required under local, state, or federal law to use any image from our Web site. <br /><br />
    Marwen has no obligation to link from its Web site to your Web site or to provide any reciprocal service of any kind.</p>

    <hr className="m32-0" />

    <p><strong>Fair Use</strong> <br /><br />
    You may use this Site and the content thereof for noncommercial educational and personal use and for “fair use,” provided that you cite the author and source of the materials. <br /><br />
    You may not copy, distribute, modify, transmit, reuse, download, or use the contents of the Site for commercial purposes without the express prior written permission of Marwen.  <br /><br />
    MARWEN MAKES NO REPRESENTATIONS OR WARRANTIES WHATSOEVER WITH RESPECT TO YOUR RIGHT TO REPRODUCE OR OTHERWISE USE ANY CONTENT OR MATERIALS, INCLUDING WORKS OF ART, ON THE SITE. It is your sole responsibility to (a) determine if it is necessary or prudent to obtain any third party rights or permissions for your use and/or if the fair use doctrine or other legal defense is applicable to your use, and (b) identify and obtain whatever copyright or other permissions may be required (if any) from an artist, agent, estate, or any other third party rights holder or representative. You agree to fully indemnify and hold harmless Marwen and its trustees, officers, employees, and agents against any and all claims, demands, liabilities, damages, and/or expenses of any nature, including without limitation reasonable attorney fees, arising out of or related to your use of content or materials on the Site.</p>

    <hr className="m32-0" />

    <p><strong>Disclaimer of Warranties</strong> <br /><br />
    Marwen assumes no liability for any errors or omissions in the content of the Site. Marwen is engaged in an ongoing process of updating and revising the materials on this Site. Marwen further reserves the right to withdraw any image, text, or data from the Site at its sole discretion at any time. Marwen assumes no liability for any damages or losses that may occur to you or any third party based on its actions. In the event that the Site is unavailable, either on a temporary or permanent basis, Marwen assumes no liability for any losses that may occur to you or any third party arising out of the unavailability of the Site. <br /><br />
    All materials on the Site are provided “AS IS” and “AS AVAILABLE” WITHOUT A WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, COPYRIGHT SUBSISTENCE, COPYRIGHT OWNERSHIP AND/OR NON-INFRINGEMENT OF COPYRIGHTS OR OTHER PROPRIETARY RIGHTS OF ANY THIRD PARTY. WE DO NOT WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT ANY DEFECTS OR WILL CORRECTED. <br /><br />
    Marwen assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your use of the Site or your downloading of any materials from the Site.</p>

    <hr className="m32-0" />

    <p><strong>Limitation of Liability</strong> <br /><br />
    MARWEN, ITS AFFILIATES, TRUSTEES, DIRECTORS, OFFICERS AND EMPLOYEES, AND OTHER AGENTS AND REPRESENTATIVES SHALL HAVE NO LIABILITY FOR ANY DAMAGES, INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL, COMPENSATORY, SPECIAL, PUNITIVE, OR INCIDENTAL DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) ARISING OUT OF OR RELATING TO THE USE OF THIS SITE. YOUR USE OF THE SITE IS AT YOUR SOLE RISK.</p>

    <hr className="m32-0" />

    <p><strong>Privacy&nbsp;</strong> <br /><br />
    Marwen may collect personal information when you visit or use the Site, become a member, request information, or enter any promotions. Marwen automatically receives and records information from your computer and browser, including your IP address, cookie information, software and hardware attributes, location information, and the page you request. Marwen may use information for the following general purposes: to customize the content that you see, contact you, fulfill your requests for products and services, improve the Site, and conduct research. </p>

    <hr className="m32-0" />

    <p><strong>Third-Party Sites</strong> <br /><br />
    Marwen has not reviewed all of the sites linked to the Site and has no control over and is not responsible for the content or privacy policies of any off-site pages or other sites linked to the Site. Any Web sites linked to or from the Site (regardless of whether they are affiliated with Marwen) are for your convenience only, and you access them at your own risk. Any information that you may provide to such third party sites are at your own risk.</p>

    <hr className="m32-0" />

    <p><strong>Severance and Waiver</strong> <br /><br />
    You acknowledge and agree that if any provision of these Terms and Conditions shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions. Furthermore, if any provision of these Terms and Conditions is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision. <br /><br />
    Marwen’s failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision unless acknowledged and agreed to in writing by Marwen.</p>

    <hr className="m32-0" />

    <p><strong>Choices of Law and Jurisdiction</strong> <br /><br />
    The Terms and Conditions are governed by the laws of the State of Illinois without regard to its conflict of laws rules. Each party hereby irrevocably consents to exclusive jurisdiction of the state and federal courts located in Cook County, Illinois.</p>

    <hr className="m32-0" />

    <p><strong>Donor Privacy</strong> <br /><br />
    We will not sell, share or trade our donors' names or personal information with any other entity, nor send mailings to our donors on behalf of other organizations. This policy applies to all information received by Marwen, both online and offline, on any Platform ("Platform", includes the Marwen website and mobile applications), as well as any electronic, written, or oral communications. To the extent any donations are processed through a third-party service provider, our donors’ information will only be used for purposes necessary to process the donation.</p>
  </div>
</section>
);

export default PrivacyPolicyPage;
