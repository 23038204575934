import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import { getRentalSpaceDataSuccessAction } from './actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = ({ globalReducer, rentSpaceReducer }) => ({
  ...globalReducer,
  ...rentSpaceReducer,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "rent-space-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/rent-space', {}, data)
    .then(({ data: { pageData, rentalSpaces }}) => {
      dispatch(pageDataLoadSuccessAction(pageData))
      dispatch(getRentalSpaceDataSuccessAction(rentalSpaces))
    })
}], mapStateToProps)
export default class RentSpacePage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}