import React from 'react';
import PropTypes from 'prop-types';
import { bp } from '../../util/constants';
import S3Background from '../S3Background';
import RenderOnClientOnly from '../RenderOnClientOnly';

export default class DeferredBackgroundImage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      src: props.autoLoad ? props.src : null,
    };
  }

  static propTypes = {
    className    : PropTypes.string,
    children     : PropTypes.object,
    srcLgx       : PropTypes.string,
    srcLg        : PropTypes.string,
    srcMd        : PropTypes.string,
    srcSm        : PropTypes.string,
    srcSmx       : PropTypes.string,
    src          : PropTypes.string.isRequired,
    autoLoad     : PropTypes.bool,
    imageIsAsset : PropTypes.bool,
    imagePath    : PropTypes.string,
  };


  componentDidMount() {
    this.boundLoadImage = this.loadImage.bind(this);
    this.boundisOnScreen = this.isOnScreeen.bind(this);
    window.addEventListener('resize', this.boundLoadImage);
    window.addEventListener('resize', this.boundisOnScreen);
    window.addEventListener('scroll', this.boundisOnScreen);
    setTimeout(this.boundisOnScreen, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.boundLoadImage);
    window.removeEventListener('resize', this.boundisOnScreen);
    window.removeEventListener('scroll', this.boundisOnScreen);
  }

  boundLoadImage = () => {};
  boundisOnScreen = () => {};

  isOnScreeen = () => {
    const { innerHeight } = window;
    if(innerHeight + 200 >= this.ele.getBoundingClientRect().top) {
      this.boundLoadImage();
      window.removeEventListener('resize', this.boundisOnScreen);
      window.removeEventListener('scroll', this.boundisOnScreen);
    }
  }

  loadImage = () => {
    const { srcLgx, srcLg, srcMd, srcSm, srcSmx, src } = this.props;
    const { innerWidth } = window;
    const currentSrc = (
        (innerWidth > bp.lg ) ? srcLgx || src
      : (innerWidth > bp.md ) ? srcLg  || srcLgx || src
      : (innerWidth > bp.sm ) ? srcMd  || srcLg  || srcLgx || src
      : (innerWidth > bp.smx) ? srcSm  || srcMd  || srcLg  || srcLgx || src
      : srcSmx || srcSm || srcMd || srcLg || srcLgx || src
    );
    this.setState({ src: currentSrc });
  }

  render() {
    const { src } = this.state;
    const { className, children, imagePath, imageIsAsset } = this.props;
    return (
    <RenderOnClientOnly className={`deferred-background-image ${className || ''}`}>
      <div
        className="h100%"
        ref={ele => this.ele = ele}
      >
      {src &&
      <S3Background
        className="h100% w100%"
        fileName={src}
        path={imagePath}
        asset={imageIsAsset}
      >
      { children }
      </S3Background>
      }
      </div>
    </RenderOnClientOnly>
    );
  }
}
