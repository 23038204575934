import {
  GET_COURSES_PAGE_DATA_SUCCESS,
  GET_COURSES_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  courses        : [],
  summerFilters  : false,
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_COURSES_PAGE_DATA_SUCCESS:
      newState.courses = action.courses;
      newState.summerFilters = action.summerFilters;
      break;
    case GET_COURSES_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Courses Page' data");
      break;
  }
  return newState;
};
