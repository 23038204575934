import { combineReducers } from 'redux';
import globalReducer from '../global-reducer';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import appReducer from './app-reducer';
import attendEventPageReducer from '../pages/AttendEventPage/reducer';
import specialEventPageReducer from '../pages/SpecialEventPage/reducer';
import coursesPageReducer from '../pages/CoursesPage/reducer';
import newsPageReducer from '../pages/NewsPage/reducer';
import peoplePageReducer from '../pages/PeoplePage/reducer';
import shopPageReducer from '../pages/ShopPage/reducer';
import cartPageReducer from '../pages/CartPage/reducer';
import rentSpaceReducer from '../pages/RentSpacePage/reducer';
import careersPageReducer from '../pages/CareersPage/reducer';
import galleryPageReducer from '../pages/GalleryPage/reducer';
import artworkPageReducer from '../pages/ArtworkPage/reducer';
import contactPageReducer from '../pages/ContactPage/reducer';
import faqPageReducer     from '../pages/FaqPage/reducer';
import coursesInActionPageReducer from '../pages/CoursesInActionPage/reducer';
import pastEventsPageReducer from '../pages/PastEventsPage/reducer';

import { isBrowser } from '../util/environment-detection';

export const createRootReducer = history => combineReducers({
  ...(isBrowser ? { router: connectRouter(history) } : {}),
  globalReducer,
  appReducer,
  attendEventPageReducer,
  specialEventPageReducer,
  coursesInActionPageReducer,
  coursesPageReducer,
  newsPageReducer,
  peoplePageReducer,
  shopPageReducer,
  cartPageReducer,
  rentSpaceReducer,
  careersPageReducer,
  artworkPageReducer,
  pastEventsPageReducer,
  galleryPageReducer,
  contactPageReducer,
  faqPageReducer,
  reduxAsyncConnect,// must be last
});
