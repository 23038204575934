import {
  GET_PEOPLE_PAGE_DATA_SUCCESS,
  GET_PEOPLE_PAGE_DATA_FAILURE,
} from './constants';

export const getPeoplePageDataSuccessAction = data => ({
  type: GET_PEOPLE_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getPeoplePageFailureAction() {
  return {
    type: GET_PEOPLE_PAGE_DATA_FAILURE,
  }
}
