import React from 'react';

export default class ErrorBoundary extends React.Component {

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    return this.state.error
      ? <div className="posa center">
          Oh no, you've encountered an error! Try refreshing the page.
        </div>
      : this.props.children;
  }
}
