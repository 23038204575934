import React from 'react';
import PropTypes from 'prop-types';

const FacebookLogo = ({ className }) => (
<svg
  viewBox="0 0 24 20"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`facebook-logo ${className || ''}`}
>
  <path d="M18.8962,0 L1.10394286,0 C0.494142857,0 0,0.494 0,1.10394286 L0,18.8962 C0,19.5058571 0.494142857,20 1.10394286,20 L10.6824857,20 L10.6824857,12.2550286 L8.07617143,12.2550286 L8.07617143,9.23645714 L10.6824857,9.23645714 L10.6824857,7.0106 C10.6824857,4.42731429 12.2603143,3.02065714 14.565,3.02065714 C15.6685143,3.02065714 16.6172,3.10282857 16.8936857,3.13965714 L16.8936857,5.83928571 L15.2957714,5.83997143 C14.0421429,5.83997143 13.7996571,6.43554286 13.7996571,7.30942857 L13.7996571,9.23645714 L16.7885143,9.23645714 L16.3992857,12.2550286 L13.7996571,12.2550286 L13.7996571,20 L18.8962,20 C19.5058571,20 20,19.5058571 20,18.8962 L20,1.10394286 C20,0.494 19.5058571,0 18.8962,0 Z"></path>
</svg>
);

FacebookLogo.propTypes = {
  className: PropTypes.string,
};

export default FacebookLogo;
