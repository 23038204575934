import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  ...state.globalReducer,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "w9-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/w9', {}, data)
      .then(({ data: { pageData }}) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class W9Page extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}