import {
  GET_SPECIAL_EVENT_PAGE_DATA_SUCCESS,
  GET_SPECIAL_EVENT_PAGE_DATA_FAILURE,
} from './constants';

export const getSpecialEventPageDataSuccessAction = event => ({
  type: GET_SPECIAL_EVENT_PAGE_DATA_SUCCESS,
  event,
})

export function getSpecialEventPageDataFailureAction() {
  return {
    type: GET_SPECIAL_EVENT_PAGE_DATA_FAILURE,
  };
}
