import {
  GET_GALLERY_PAGE_DATA_SUCCESS,
  GET_GALLERY_PAGE_DATA_FAILURE,
} from './constants';

export const getGalleryPageDataSuccessAction = album => ({
  type: GET_GALLERY_PAGE_DATA_SUCCESS,
  album,
})

export  function getGalleryPageDataFailureAction() {
  return {
    type: GET_GALLERY_PAGE_DATA_FAILURE,
  }
}
