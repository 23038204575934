import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../svg/Logo';
import InstagramLogo from '../../svg/InstagramLogo';
import FacebookLogo from '../../svg/FacebookLogo';
import VimeoLogo from '../../svg/VimeoLogo';
import TikTokLogo from '../../svg/TikTokLogo';
import RenderOnClientOnly from '../../blocks/RenderOnClientOnly';
import { isBrowser } from '../../util/environment-detection';

const Footer = ({ className }) => (
<footer className={`footer bgc-pale-blue pt44 ${className || ''}`}>
  <div className="grid-container posr">
    <div className="clearfix mb16 fll">
      <div className="fll ml70 w110 mr110 lh1 fln@sm pb16@sm m0-auto@sm">
        <Link
          className="h20 f-purple f-brass:h"
          to={'/'}
        >
          <Logo className="h20"/>
        </Link>
      </div>
      <div className="footer-links fll ttu lh1 tac@sm fln@sm">
        <div className="mb8">
          {
            /*<a
              className="c-brass:h fw400 tdn dib w150 fz14"
              href="/registration"
            >
              Register
            </a>*/
          }
          <Link className="c-brass:h fw400 tdn dib w150 fz14" to={'/contact'}>Contact Us</Link>
        </div>
        <div className="mb8">
          <Link className="c-brass:h fw400 tdn dib w150 fz14" to={'/donate'}>Donate Now</Link>
          <Link className="c-brass:h fw400 tdn dib w150 fz14" to={'/privacy'}>Privacy Policy</Link>
        </div>
        <div className="mb8">
          <Link className="c-brass:h fw400 tdn dib w150 fz14" to={'/careers'}>Careers</Link>
          <Link className="c-brass:h fw400 tdn dib w150 fz14" to="/threadless">Threadless</Link>
          <Link className="c-brass:h fw400 tdn dn w150 fz14" to={'/faqs'}>FAQs</Link>
        </div>
        <RenderOnClientOnly>
        <div>
        {isBrowser && window.__USER &&
        <div className="mb8">
          <a className="c-brass:h fw400 tdn dib w150 fz14" href="/keystone">Keystone</a>
          <a className="c-brass:h fw400 tdn dib w150 fz14" href="/api/load-flickr-data">Refresh Flickr</a>
        </div>
        }
        {isBrowser && window.__USER &&
        <div>
          <a className="c-brass:h fw400 tdn dib w150 fz14" href="/api/reload-all-cache">Refresh ALl Cache</a>
        </div>
        }
        </div>
        </RenderOnClientOnly>
      </div>
    </div>
    <div className="social-media tar dib flr tac@md">
      <a
        href="https://vimeo.com/marwenarts"
        target="_blank"
        rel="noopener noreferer"
        className="dib mr44 mr20@sm w25 posr"
      >
        <VimeoLogo className="h20 f-purple f-brass:h" />
      </a>
      <a
        href="https://www.facebook.com/MarwenChicago"
        target="_blank"
        rel="noopener noreferer"
        className="dib mr44 mr20@sm w25 posr"
      >
        <FacebookLogo className="h20 f-purple f-brass:h" />
      </a>
      <a
        href="https://www.instagram.com/marwenarts/"
        target="_blank"
        rel="noopener noreferer"
        className="dib mr44 mr20@sm w25 posr"
      >
        <InstagramLogo className="h20 f-purple f-brass:h" />
      </a>
      <a
        href="https://www.tiktok.com/@marwenchicago"
        target="_blank"
        rel="noopener noreferer"
        className="dib mr44 mr20@sm w25 posr"
      >
        <TikTokLogo className="h20 f-purple f-brass:h" />
      </a>
    </div>
  </div>
</footer>
);

export default Footer;
