import {
  GET_SHOP_PAGE_DATA_SUCCESS,
  GET_SHOP_PAGE_DATA_FAILURE,

} from './constants';

const initialState = {
  products         : [],
  productCategories: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_SHOP_PAGE_DATA_SUCCESS:
      newState.products = action.products;
      newState.productCategories = action.productCategories;
      break;
    default: break;
  }
  return newState;
};
