import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_FROM_CART,
  UPDATE_PRODUCT_COUNT,
  CUSTOMIZE_PRODUCT,
} from './app-constants';

export const addToCartAction = product => ({
  type: ADD_TO_CART,
  product,
})

export const customizeProductAction = (slug, customOptions) => ({
  type: CUSTOMIZE_PRODUCT,
  slug,
  customOptions,
})

export const removeFromCartAction = id => ({
  type: REMOVE_FROM_CART,
  id,
})

export const updateProductCountAction = (id, count) => ({
  type: UPDATE_PRODUCT_COUNT,
  id,
  count,
})

export const clearCartAction = (charge, serverCart) => ({
  type: CLEAR_CART,
  charge,
  serverCart,
})
