import {
  GET_PAST_EVENTS_PAGE_DATA_SUCCESS,
  GET_PAST_EVENTS_PAGE_DATA_FAILURE,
} from './constants';

export const getPastEventsPageDataSuccessAction = data => ({
  type: GET_PAST_EVENTS_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getPastEventsPageDataFailureAction() {
  return {
    type: GET_PAST_EVENTS_PAGE_DATA_FAILURE,
  }
}
