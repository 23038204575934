import {
  GET_FAQ_PAGE_DATA_SUCCESS,
  GET_FAQ_PAGE_DATA_FAILURE,
} from './constants';

export const getFaqPageDataSuccessAction = data => ({
  type: GET_FAQ_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getFaqPageDataFailureAction() {
  return {
    type: GET_FAQ_PAGE_DATA_FAILURE,
  }
}
