import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "college-center-page-spanish" */'./spanishComponent')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/college-and-career-support', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps)
export default class CollegeCenterPageSpanish extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}