import {
  GET_FAQ_PAGE_DATA_SUCCESS,
  GET_FAQ_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  faqs: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_FAQ_PAGE_DATA_SUCCESS:
      newState.faqs = action.faqs;
      break;
    case GET_FAQ_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Faq Page' data");
      break;
  }
  return newState;
};
