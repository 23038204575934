import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getCoursesInActionPageDataSuccessAction,
  getCoursesInActionPageDataFailureAction,
} from './actions';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData : state.globalReducer.pageData,
  ...state.coursesInActionPageReducer,
})

const Page = loadable(() =>
  import(/* webpackChunkName: "courses-in-action-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/courses-in-action', { galleryType: 'Courses in Action' }, data)
      .then(({ data: { pageData, albums }}) => {
        dispatch(getCoursesInActionPageDataSuccessAction({ albums }));
        dispatch(pageDataLoadSuccessAction(pageData));
      })
}], mapStateToProps)
export default class CoursesInActionPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}