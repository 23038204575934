import {
  GET_ARTWORK_PAGE_DATA_SUCCESS,
  GET_ARTWORK_PAGE_DATA_FAILURE,
} from './constants';

export const getArtworkPageDataSuccessAction = data => ({
  type: GET_ARTWORK_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getArtworkPageDataFailureAction() {
  return {
    type: GET_ARTWORK_PAGE_DATA_FAILURE,
  }
}
