import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const innerStyle = color => `posr mt-4 ml-4 mb4 dib h40 miw250 miw200@md bdrs50 bgc-white fw700 c-dark-gray lts1 bd1-s-${ color || 'purple'} ttu tac fz13 trs-all-0.2s-eio`;

const PrimaryButton = ({ text, className, color, to, href, onClick, target, disabled }) => (
<div
  className={`primary-button dib ${disabled === true ? 'primary-button--disabled' : ''} mr4 ${className || ''}`}
  onClick={e => !disabled && onClick && onClick(e)}
>
  <div className={`primary-button__wrapper pr4 lh1 h40 bdrs50 dib curp bgc-${ color || 'purple'} trs-all-0.2s-eio`}>
    {to &&
    <Link to={to} target={target} className={`primary-button__link vasub tdn ${innerStyle(color)}`}>
      <span className="posa center p0-20 w100%">{ text }</span>
    </Link>
    }
    {href &&
    <a href={href} target={target} className={`primary-button__link vasub tdn ${innerStyle(color)}`}>
      <span className="posa center p0-20 w100%">{ text }</span>
    </a>
    }
    {onClick &&
    <button className={`primary-button__link ${innerStyle(color)}`}>
      <span className="posa center p0-20 w100%">{ text }</span>
    </button>
    }
    {!(to || href || onClick) &&
    <button className={`primary-button__link ${innerStyle(color)}`}>
      <span className="posa center p0-20 w100%">{ text }</span>
    </button>
    }
  </div>
</div>
);

PrimaryButton.propTypes = {
  text     : PropTypes.string.isRequired,
  className: PropTypes.string,
  color    : PropTypes.string,
  to       : PropTypes.string,
  href     : PropTypes.string,
  onClick  : PropTypes.func,
  target   : PropTypes.string,
  disabled : PropTypes.bool,
};

export default PrimaryButton;
