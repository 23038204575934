import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { bp } from '../../../util/constants';

/**
 * Helper function to see if a child element is contained in a parent element
 * @param  {Node?} child  Either a Node or null
 * @param  {Node} parent  A Node
 * @return {bool}         True if the child is in the parent
 */
function containedIn(child, parent) {
  if(!child) return false;
  return (parent === child)
    ? true
    : containedIn(child.parentNode, parent);
}

export default class Navigation extends React.Component {

  constructor() {
    super();
    this.state = {
      sectionIsActive: () => {},
    }
  }

  static propTypes = {
    currentActiveSection: PropTypes.string.isRequired,
    currentNavSection   : PropTypes.string.isRequired,
    mobileNavOpen       : PropTypes.bool.isRequired,
    openNavSection      : PropTypes.func.isRequired,
    openMobileNav       : PropTypes.func.isRequired,
    closeMobileNav      : PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.body.addEventListener('touchstart', (e) => {
      if(!containedIn(e.target, this.navMenu))
        this.props.openNavSection('');
    });

    this.setState({
      sectionIsActive: section =>
        section === this.props.currentActiveSection ||
        section === this.props.currentNavSection
    })
  }

  clearNavSection = () => this.props.openNavSection('')

  closeNavigation = () => this.props.closeMobileNav()

  toggleMenu = () => {
    const { mobileNavOpen, openMobileNav, closeMobileNav } = this.props;
    mobileNavOpen ? closeMobileNav() : openMobileNav();
  }

  render() {
    const {
      currentActiveSection,
      currentNavSection,
      openNavSection,
      mobileNavOpen,
    } = this.props;
    const { sectionIsActive } = this.state;
    return (
      <div className="navigation dib posa w100% h100% r0 t0 tar">
        <nav
          className={`navigation__menu posa w100% h100%`}
          onMouseLeave={() => window.innerWidth > bp.lg && openNavSection('')}
          ref={navMenu => this.navMenu = navMenu}
        >
          <div className="menu__mobile-toggle h100% bgc-white w50 flr dn db@lg">
            <button
              className="fz32 tac w50"
              onClick={this.toggleMenu}
            >
              <div className={`lh0 tar ${mobileNavOpen ? 'dn' : 'db'}`}>
                <div className="mt-6"><span className="dib bgc-black h2 w16 mr16"></span></div>
                <div className="mt-6"><span className="dib bgc-black h2 w16 mr16"></span></div>
                <div className="mt-6"><span className="dib bgc-black h2 w8 mr16"></span></div>
              </div>
              <span className={`${mobileNavOpen ? 'db' : 'dn'}`}>&times;</span>
            </button>
          </div>

          <div className={`posr fz12 ttu w100% bgc-white@lg posa@lg t40@lg p20@lg ${mobileNavOpen ? 'db' : 'dn@lg' }`}>
            <div className="grid-container--wide pl10 pr10 tal@lg">
              <div className="posr l50 pr50">
                <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('new-to-marwen')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('new-to-marwen')}
                >
                  <span className="posr h100% p20-0 dib">
                    New to Marwen
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-purple ${sectionIsActive('new-to-marwen') ? 'db' : 'dn'}`}></span>
                  </span>
                </span>
                <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('students-and-alumni')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('students-and-alumni')}
                >
                  <span className="posr h100% p20-0 dib">
                    Students &amp; Alumni
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-brass ${sectionIsActive('students-and-alumni') ? 'db' : 'dn'}`}></span>
                  </span>
                </span>
                <a
                  className="navigation__item fw400 posr curp dib pr30 h44@lg db@lg fz12 tdn"
                  target="_blank"
                  href="https://marwenarts.wixsite.com/blog/blog"
                  onClick={() => { this.clearNavSection(); this.closeNavigation(); }}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('')}
                >
                  <span className="posr h100% p20-0 dib">
                    Blog
                    <span className={`navigation__item__active posa dn b2 l0 h4 w100% bgc-dark-blue`}></span>
                  </span>
                </a>
                {/* <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('teaching-artists')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('teaching-artists')}
                >
                  <span className="posr h100% p20-0 dib">
                    Teaching Artists
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-dark-blue ${sectionIsActive('teaching-artists') ? 'db' : 'dn'}`}></span>
                  </span>
                </span> */}
                <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('galleries')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('galleries')}
                >
                  <span className="posr h100% p20-0 dib">
                    Galleries
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-bright-red ${sectionIsActive('galleries') ? 'db' : 'dn'}`}></span>
                  </span>
                </span>
                <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('our-impact')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('our-impact')}
                >
                  <span className="posr h100% p20-0 dib">
                    About
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-neon-yellow ${sectionIsActive('our-impact') ? 'db' : 'dn'}`}></span>
                  </span>
                </span>
                <span
                  className="navigation__item curp dib pr20 h44@lg db@lg"
                  onClick={() => openNavSection('ways-to-support')}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('ways-to-support')}
                >
                  <span className="posr h100% p20-0 dib">
                    Support
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-almond ${sectionIsActive('ways-to-support') ? 'db' : 'dn'}`}></span>
                  </span>
                </span>
                <a
                  className="navigation__item fw400 posr curp dib pr30 h44@lg db@lg fz12 tdn"
                  href="https://www.marwenimpact2023.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => { this.clearNavSection(); this.closeNavigation(); }}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('')}
                >
                  <span className="posr h100% p20-0 dib">
                    Impact Report
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-mid-blue ${sectionIsActive('shop') ? 'db' : 'dn'}`}></span>
                  </span>
                </a>
                <a
                  className="navigation__item fw400 posr curp dib pr30 h44@lg db@lg fz12 tdn"
                  href="/registration"
                  onClick={() => { this.clearNavSection(); this.closeNavigation(); }}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('')}
                >
                  <span className="posr h100% p20-0 dib">
                    Registration
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-french-rose ${sectionIsActive('registration') ? 'db' : 'dn'}`}></span>
                  </span>
                </a>
                <Link
                  className="navigation__item fw400 posr curp dib h44@lg db@lg fz12 tdn"
                  to={'/donate'}
                  onClick={() => { this.clearNavSection(); this.closeNavigation(); }}
                  onMouseEnter={() => window.innerWidth > bp.lg && openNavSection('')}
                >
                  <span className="posr h100% p20-0 dib">
                    Donate
                    <span className={`navigation__item__active posa b2 l0 h4 w100% bgc-mid-blue ${sectionIsActive('donate') ? 'db' : 'dn'}`}></span>
                  </span>
                </Link>
                <span className="fw400 posr curp dib pr30 h44@lg db@lg fz12 tdn dn@md" style={{ visibility: 'hidden'}}>Registration</span>
              </div>
            </div>
            <div
              className={`menu__off-menu-close posa t100% h100vh w100% ${mobileNavOpen ? 'db' : 'dn' }`}
              onClick={this.closeNavigation}
            ></div>
          </div>
          <div className={`menu__flyout bgc-white posa w100% t100% transition-top tal posa@lg w100%@lg h375@lg p20@lg ${currentNavSection === '' ? 'l100%@lg' : ' l0@lg'}`}>
            <div className="grid-container--wide">
              <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l157 h100%@lg l0@lg p0@sm ${currentNavSection === 'new-to-marwen' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-purple:h c-white:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/become-a-student'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-purple:h c-white:h"
                  >
                    Become a Student
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/parents-and-families'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-purple:h c-white:h"
                  >
                    Parents &amp; Families
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/educators-and-organizers'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-purple:h c-white:h"
                  >
                    Educators &amp; Organizations
                  </Link>
                </li>
                {
                // <li className="sub-nav-item w100% h26 h40@lg">
                //   <Link
                //     onClick={this.closeNavigation}
                //     to={'/become-a-teacher'}
                //     className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-purple:h c-white:h"
                //   >
                //     Become a Teaching Artist
                //   </Link>
                // </li>
                }
              </ul>
              <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l276 h100%@lg l0@lg p0@sm ${currentNavSection === 'students-and-alumni' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-brass:h c-white:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/latest-news'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-brass:h c-white:h"
                  >
                    Latest News
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/courses-and-workshops'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-brass:h c-white:h"
                  >
                    Courses &amp; Workshops
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/college-and-career-support'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-brass:h c-white:h"
                  >
                    College & Career Support
                  </Link>
                </li>
              </ul>
              {/* <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l400 h100%@lg l0@lg p0@sm ${currentNavSection === 'teaching-artists' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-dark-blue:h c-white:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/resources-center'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-dark-blue:h c-white:h"
                  >
                    Resources
                  </Link>
                </li>
              </ul> */}
              <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l475 h100%@lg l0@lg p0@sm ${currentNavSection === 'galleries' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-bright-red:h c-white:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/artwork'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-bright-red:h c-white:h"
                  >
                    Marwen Art
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/courses-in-action'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-bright-red:h c-white:h"
                  >
                    Courses in Action
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/past-events'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-bright-red:h c-white:h"
                  >
                    Past Events
                  </Link>
                </li>
              </ul>
              <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l553 h100%@lg l0@lg p0@sm ${currentNavSection === 'our-impact' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-neon-yellow:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/what-we-do'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-neon-yellow:h"
                  >
                    What We Do
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/our-facility'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-neon-yellow:h"
                  >
                    Our Facility
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/our-people'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-neon-yellow:h"
                  >
                    Our People
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/news-and-publications'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-neon-yellow:h"
                  >
                    News &amp; Publications
                  </Link>
                </li>
              </ul>
              <ul
                className={`flyout__item pt24 w200 w250@lg h144 posr p20@lg l613 h100%@lg l0@lg p0@sm ${currentNavSection === 'ways-to-support' ? 'db' : 'dn'}`}
              >
                <li
                  className="sub-nav-item w100% sub-nav-item--back p4-0 pl16 pr16 bgc-almond:h h40@lg dn db@lg fz12 curp"
                  onClick={this.clearNavSection}
                >
                  &lt; Back
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/donate'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-almond:h"
                  >
                    Donate
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/attend-an-event'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-almond:h"
                  >
                    Attend An Event
                  </Link>
                </li>
                <li className="sub-nav-item w100% h26 h40@lg">
                  <Link
                    onClick={this.closeNavigation}
                    to={'/shop'}
                    className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-almond:h"
                  >
                    Shop
                  </Link>
                </li>
                {
                // <li className="sub-nav-item w100% h26 h40@lg">
                //   <Link
                //     onClick={this.closeNavigation}
                //     to={'/rent-space'}
                //     className="sub-nav-item__link fw400 dib w100% p4-0 pl16 pr16 tdn fz12 bgc-almond:h"
                //   >
                //     Space Rental
                //   </Link>
                // </li>
                }
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
