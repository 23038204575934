import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  getShopPageDataSuccessAction,
  getShopPageDataFailureAction,
} from './actions';
import {
  addToCartAction,
  removeFromCartAction,
  updateProductCountAction,
} from '../../website-main/app-actions';
import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  ...state.globalReducer,
  cart : state.appReducer.cart,
  ...state.shopPageReducer,
})

const mapDispatchToProps = dispatch => ({
  addToCart         : product => dispatch(addToCartAction(product)),
  removeFromCart    : id => dispatch(removeFromCartAction(id)),
  updateProductCount: (id, count) => dispatch(updateProductCountAction(id, count)),
  openLightBox      : config => dispatch(openLightboxAction(config)),
  closeLightBox     : () => dispatch(closeLightboxAction()),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "shop-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/shop', {}, data)
      .then(({ data: { pageData, products, productCategories }}) => {
        dispatch(getShopPageDataSuccessAction({ products, productCategories }));
        dispatch(pageDataLoadSuccessAction(pageData));
      })
}], mapStateToProps, mapDispatchToProps)
export default class ShopPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}