import React from 'react';
import PropTypes from 'prop-types';

const VimeoLogo = ({ className }) => (
<svg
  viewBox="0 0 24 20"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`vimeo-logo ${className || ''}`}
>
  <path d="M23.1137002,4.62659999 C23.0107665,6.87754309 21.4387596,9.95915645 18.3976798,13.8714401 C15.2533995,17.9565245 12.5931213,20 10.4179118,20 C9.06937267,20 7.92883439,18.7562613 6.99683035,16.2677171 C6.37469431,13.9863739 5.75309162,11.7052974 5.13068892,9.42395413 C4.43841925,6.93647668 3.69654937,5.69113794 2.90241259,5.69113794 C2.72934517,5.69113794 2.12400921,6.05540619 1.08747138,6.78020933 L0,5.37913659 C1.14133828,4.37673224 2.26694317,3.37406123 3.37441463,2.37005687 C4.89655457,1.0553845 6.0400262,0.363381502 6.8016295,0.293514532 C8.60057063,0.120713783 9.70857544,1.35058578 10.1240439,3.9833972 C10.5733792,6.82447619 10.8848472,8.59115052 11.0589813,9.28262018 C11.5784502,11.6396971 12.149386,12.8170355 12.7725887,12.8170355 C13.2560575,12.8170355 13.9835273,12.0527655 14.9531315,10.5231589 C15.920869,8.99461894 16.4398046,7.83141389 16.5096716,7.03194376 C16.6480722,5.71247137 16.1291366,5.05140183 14.9531315,5.05140183 C14.3995291,5.05140183 13.8285933,5.17886905 13.2413908,5.43060348 C14.3781957,1.70872067 16.5494051,-0.0990205036 19.754219,0.00417994396 C22.130496,0.0737802458 23.2510342,1.61458693 23.1137002,4.62659999"></path>
</svg>
);

VimeoLogo.propTypes = {
  className: PropTypes.string,
};

export default VimeoLogo;
