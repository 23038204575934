import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

import {
  pageDataLoadSuccessAction,
  pageDataLoadFailureAction,
  openLightboxAction,
  closeLightboxAction,
} from '../../global-actions';

import { apiRequest } from '../../util/api-request';

const mapStateToProps = state => ({
  pageData: state.globalReducer.pageData,
})

const mapDispatchToProps = (dispatch) => ({
  openLightBox      : config => dispatch(openLightboxAction(config)),
  closeLightBox     : () => dispatch(closeLightboxAction()),
})

const Page = loadable(() =>
  import(/* webpackChunkName: "parents-page" */'./component')
)

@asyncConnect([{
  promise: ({ params, helpers, store: { dispatch }, data }) =>
    apiRequest('page/parents-and-families', {}, data)
      .then(({ data: { pageData } }) => dispatch(pageDataLoadSuccessAction(pageData)))
}], mapStateToProps, mapDispatchToProps)
export default class ParentsPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}