import {
  GET_ATTEND_EVENT_PAGE_DATA_SUCCESS,
  GET_ATTEND_EVENT_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  events : [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_ATTEND_EVENT_PAGE_DATA_SUCCESS:
      newState.events = action.events;
      break;
    case GET_ATTEND_EVENT_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'Attend Event Page' data");
      break;
  }
  return newState;
};
