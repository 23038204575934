import React from 'react';
import PropTypes from 'prop-types';

const InstagramLogo = ({ className }) => (
<svg
  viewBox="0 0 24 20"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`instagram-logo ${className || ''}`}
>
  <path d="M17.692928,8.46062199 L15.951791,8.46062199 C16.0784937,8.95311916 16.1536895,9.46757515 16.1536895,9.99958147 C16.1536895,13.3989029 13.3983728,16.1536895 9.99986049,16.1536895 C6.60148773,16.1536895 3.84631053,13.3989029 3.84631053,9.99958147 C3.84631053,9.46757515 3.92150626,8.95311916 4.04848799,8.46062199 L2.30721154,8.46062199 L2.30721154,16.9228902 C2.30721154,17.3471392 2.65177261,17.6915608 3.07724932,17.6915608 L16.9226112,17.6915608 C17.3482274,17.6915608 17.692928,17.3471392 17.692928,16.9228902 L17.692928,8.46062199 Z M17.692928,3.07641226 C17.692928,2.65177261 17.3482274,2.30721154 16.9226112,2.30721154 L14.6155391,2.30721154 C14.1907321,2.30721154 13.8463384,2.65177261 13.8463384,3.07641226 L13.8463384,5.38446085 C13.8463384,5.8091284 14.1907321,6.15366157 14.6155391,6.15366157 L16.9226112,6.15366157 C17.3482274,6.15366157 17.692928,5.8091284 17.692928,5.38446085 L17.692928,3.07641226 L17.692928,3.07641226 Z M9.99986049,6.15366157 C7.87560198,6.15366157 6.15366157,7.87535087 6.15366157,9.99958147 C6.15366157,12.1237005 7.87560198,13.8463384 9.99986049,13.8463384 C12.1242585,13.8463384 13.8463384,12.1237005 13.8463384,9.99958147 C13.8463384,7.87535087 12.1242585,6.15366157 9.99986049,6.15366157 L9.99986049,6.15366157 Z M17.692928,20 L2.30721154,20 C1.03309728,20 0,18.9664842 0,17.6915608 L0,2.30721154 C0,1.03295777 1.03309728,0 2.30721154,0 L17.692928,0 C18.9673212,0 20,1.03295777 20,2.30721154 L20,17.6915608 C20,18.9664842 18.9673212,20 17.692928,20 L17.692928,20 Z"></path>
</svg>
);

InstagramLogo.propTypes = {
  className: PropTypes.string,
};

export default InstagramLogo;
