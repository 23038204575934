import React from 'react';
import { asyncConnect } from 'redux-connect';
import loadable from '@loadable/component';

const Page = loadable(() =>
  import(/* webpackChunkName: "rent-space-enquiry-page" */'./component')
)

export default class RentSpaceEnquiryPage extends React.Component {
  render() {
    return <Page {...this.props} />
  }
}