import {
  GET_SHOP_PAGE_DATA_SUCCESS,
  GET_SHOP_PAGE_DATA_FAILURE,
} from './constants';

export const getShopPageDataSuccessAction = data => ({
  type: GET_SHOP_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getShopPageDataFailureAction() {
  return {
    type: GET_SHOP_PAGE_DATA_FAILURE,
  }
}
