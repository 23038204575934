import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ReduxAsyncConnect } from 'redux-connect';
import { ConnectedRouter } from 'connected-react-router';
import { loadableReady } from '@loadable/component';

import { setGlobalInitialState } from '../both/global-reducer';
import { createRootReducer }  from '../both/website-main/reducers';
import { setAppInitialState } from '../both/website-main/app-reducer';
import routes                 from '../both/website-main/routes';

import { configureStore, history }     from '../both/create-store';

// bring in css
import '../scss/site.scss';

// This value is rendered into the DOM by the server
const initialState = window.__INITIAL_STATE;

// set the initial state of the global and app site configuration
if(initialState.globalReducer)
  setGlobalInitialState(initialState.globalReducer);
if(initialState.appReducer)
  setAppInitialState(initialState.appReducer);

// Create store with the initial state generated by the server
const store = configureStore(initialState, createRootReducer);

loadableReady(() =>
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ReduxAsyncConnect routes={routes} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
  )
)
