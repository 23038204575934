import React from 'react';
import PropTypes from 'prop-types';
import LineBackground from '../../svg/LineBackground';

export default class DotBackground extends React.Component {

  constructor() {
    super();
    this.state = {
      right : '0%',
    };
  }

  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
  };

  calcRight = () => (this.pHeight() - window.innerHeight > 500)
    ? `${(parseFloat((window.scrollY * 1.4) / this.pHeight(), 10) + -0.5) * 100}%`
    : `0%`

  pHeight = () => document.body.clientHeight - window.innerHeight

  positionBackground = () => this.setState({ right: this.calcRight() })

  componentDidMount() {
    const { history } = this.props;
    window.addEventListener('scroll', this.positionBackground)
    // function that fires whenever the history updates
    history.listen(e => setTimeout(() => {
      this.positionBackground();
    } , 0));
    this.positionBackground();
  }

  render() {
    const { right } = this.state;
    const { className } = this.props;

    return (
    <div
      className={`dot-background posf h100% w100% t0 ${className || ''}`}
      style={{ right }}
    >
      <LineBackground className="posr t0 l0 w100% dn@sm" />
    </div>
    );
  }
}
