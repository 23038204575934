import {
  GET_COURSES_IN_ACTION_PAGE_DATA_SUCCESS,
  GET_COURSES_IN_ACTION_PAGE_DATA_FAILURE,
} from './constants';

export const getCoursesInActionPageDataSuccessAction = data => ({
  type: GET_COURSES_IN_ACTION_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getCoursesInActionPageDataFailureAction() {
  return {
    type: GET_COURSES_IN_ACTION_PAGE_DATA_FAILURE,
  }
}