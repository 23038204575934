import React from 'react';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_PRODUCT_COUNT,
  CLEAR_CART,
  CUSTOMIZE_PRODUCT,
} from './app-constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { isBrowser } from '../util/environment-detection';
import { registrationRootPathnames } from '../util/constants';

let cart = {};
if(isBrowser)
  cart = JSON.parse(localStorage.getItem('cart'))||{};

let initialState = {
  cart,
  finalizedCart: {},
};

// This is so we can load in the site configuration
export const setAppInitialState = state => {
  state.cart = cart;
  initialState = {
    ...initialState,
    ...state,
  };
}

const emptyProductCustomOptions = {
  font    : '',
  color   : '',
  message : '',
};

const saveCart = cart => isBrowser && localStorage.setItem('cart', JSON.stringify(cart));

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    // redirect to registration
    case LOCATION_CHANGE:
      if(registrationRootPathnames.includes(action.payload.location.pathname)) {
        window.location.href = action.payload.location.pathname;
        throw 'Redirecting to registration';
      }
      break;
///
/// shopping actions
///
    case ADD_TO_CART:
      newState.cart = {...newState.cart};
      if(newState.cart[action.product.slug])
        newState.cart[action.product.slug].count++;
      else
        newState.cart[action.product.slug] = {
          count: 1,
          product: action.product,
          customOptions: { ...emptyProductCustomOptions },
        };
      saveCart(newState.cart);
      break;
    case REMOVE_FROM_CART:
      if(newState.cart[action.id])
        delete newState.cart[action.id];
      newState.cart = {...newState.cart};
      saveCart(newState.cart);
      break;
    case UPDATE_PRODUCT_COUNT:
      if(newState.cart[action.id]){
        newState.cart[action.id].count = action.count;
        if(newState.cart[action.id].count <= 0)
          delete newState.cart[action.id];
        newState.cart = {...newState.cart};
        saveCart(newState.cart);
      }
      break;
    case CLEAR_CART:
      newState.finalizedCart.items = { ...action.serverCart };
      newState.finalizedCart.charge = action.charge;
      newState.cart = {};
      newState.cart = {...newState.cart};
      saveCart(newState.cart);
      break;
    case CUSTOMIZE_PRODUCT:
      newState.cart[action.slug].customOptions = action.customOptions;
      newState.cart = {...newState.cart};
      saveCart(newState.cart);
      break;
    default: break;
  }
  return newState;
};
