import {
  GET_NEWS_PAGE_DATA_SUCCESS,
  GET_NEWS_PAGE_DATA_FAILURE,
} from './constants';

export const getNewsPageDataSuccessAction = data => ({
  type: GET_NEWS_PAGE_DATA_SUCCESS,
  ...data,
})

export  function getNewsPageDataFailureAction() {
  return {
    type: GET_NEWS_PAGE_DATA_FAILURE,
  }
}
