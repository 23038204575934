import React from 'react';
import PropTypes from 'prop-types';

const RoundedCorner = ({ className }) => (
<svg
  viewBox="0 0 160 160"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  className={`rounded-corner ${className || ''}`}
>
  <path d="M152,0 L160,0 L160,160 L0,160 L0,152 L51.9929999,152 C107.237535,152 152,107.227517 152,51.9978608 L152,-3.55271368e-15 Z"></path>
</svg>
);

RoundedCorner.propTypes = {
  className: PropTypes.string,
};

export default RoundedCorner;
