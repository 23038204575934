import {
  GET_NEWS_PAGE_DATA_SUCCESS,
  GET_NEWS_PAGE_DATA_FAILURE,
} from './constants';

const initialState = {
  pressReleases: [],
};

export default function reducer(state = initialState, action) {
  const newState = { ...state };
  switch(action.type) {
    case GET_NEWS_PAGE_DATA_SUCCESS:
      newState.pressReleases = action.pressReleases;
      break;
    case GET_NEWS_PAGE_DATA_FAILURE:
      console.warn("Problem loading 'News Page' data");
      break;
  }
  return newState;
};
