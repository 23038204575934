export const ADD_TO_CART             = 'app-main/ADD_TO_CART';
export const REMOVE_FROM_CART        = 'app-main/REMOVE_FROM_CART';
export const UPDATE_PRODUCT_COUNT    = 'app-main/UPDATE_PRODUCT_COUNT';
export const CUSTOMIZE_PRODUCT       = 'app-main/CUSTOMIZE_PRODUCT';
export const CLEAR_CART              = 'app-main/CLEAR_CART';
export const OPEN_LIGHTBOX           = 'app-main/OPEN_LIGHTBOX';
export const CLOSE_LIGHTBOX          = 'app-main/CLOSE_LIGHTBOX';

// these are the different types of galleries, mostly defined here for reference
export const galleryTypes = {
  'Artwork'          : 1,
  'Past Events'      : 2,
  'Courses in Action': 3,
  1        : 'Artwork',
  2        : 'Past Events',
  3        : 'Courses in Action',
};
