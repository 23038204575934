import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';

export default class DonationSuccess extends React.Component {

  render() {
    return (
    <section className="donation-success-page">
      <Helmet
        title="Donation Successful | Marwen"
      />
      <div className="h300 bgc-mid-blue"></div>
      <div className="grid-container --full-bleed@lg mt-208 mb104 mb0@md">
        <div className="bgc-white clearfix p32 p8@sm">
          <div className="ttu fw700 lts5 lh1.5 fz16 mb24 mb12@md c-black">
            Thanks for your Donation!
          </div>
        </div>
        <div className="w100% tac bgc-pale-blue pt50 pb50">
          <PrimaryButton
            className=""
            text="Back to Marwen"
            to="/"
          />
        </div>
      </div>
    </section>
    );
  }
}
