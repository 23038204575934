import React from 'react';
import Helmet from 'react-helmet';
import Hero from '../../components/Hero';
import BackButton from '../../blocks/BackButton';
import PrimaryButton from '../../components/PrimaryButton';
import S3Background from '../../blocks/S3Background';

const NotFoundPage = () => (
<section>
  <Hero
    className="bgc-purple"
    titleText="We aren’t sure what you’re looking for. But here is some art:"
    titleClassName="c-white"
    eyebrowText="OOPS 404!"
    eyebrowClassName="c-white"
  />
  <S3Background
    className="w100% h600 h300@sm posr"
    fileName="/images/NotFoundPage/bodyBG.jpg"
    asset={true}
  >
    <BackButton className="t80% posa center-hori">
      <PrimaryButton
        text="Go Back"
      />
    </BackButton>
  </S3Background>
</section>
);

export default NotFoundPage;
